import React, { memo, useState, useReducer, useEffect } from 'react';
import { Handle, Position, BaseNode, NodeLabelRenderer, useReactFlow, } from 'reactflow';
import './index.css';
import { useStoreContext } from '../state';


const infoInitialState = {
  product: "",
  version: "",
  number: "",
  docType: "",
  issueNumber: "",
  path: "",
  status: "",
  document: "",
}

const controlsInitialState ={
  collapse: false,
  onControlsChange: ()=>{},
}  


const ButtonNode = ({ id, data }) => {


  const {getPrevious} = useStoreContext()


  const handleDelete = () => {
    data.info.onDelete(id);
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    data.info.onInfoChange(id, {[name]: value})
  };

  const handleCollapse = (e) => {
    data.controls.onControlsChange(id, {collapse: !data.controls.collapse})
  }

  const closeButton =  {  
    position: "absolute",
    top: "0px", /* Adjust as needed */
    right: "0px", /* Adjust as needed */
    color: "red",
    display: "flex",
    alignItems: "center",
  }

  const labelStyle = { 
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    }

  const input = {
    display: "flex",
    alignItems: "center", 
    width:"100%",
    gap:"1rem",
    textAlign: "right", 
  }

  const handleGetDependants = () => {
    getPrevious(id)
  }

  return (
    <div style={{ 
      padding: 10,
      border: '0px solid #ddd',
      borderRadius: 5,
      marginRight:0,
      minWidth: 150, 
      background: data.info.status === "COMPLETED" ? "#6c6" : data.info.status === "REVIEW" ? "#66F" : "#eee",
    }}>
      <button style={{position: "absolute", top: "1px", left: "1px", display: "flex", alignItems: "center",}} onClick={handleCollapse}>{data.controls.collapse ? <>&or;</> : <>&and;</>}</button>
      <button style={closeButton} onClick={handleDelete}>&times;</button>
      <button onClick={handleGetDependants}>D</button>
      <div style={{marginTop: "10px"}}>
        {!data.controls.collapse ? Object.keys(infoInitialState).map((key) =>
          <label style={labelStyle}>
            {key.charAt(0).toUpperCase() + key.slice(1)}:&nbsp;&nbsp;
            <input
              id={`${data.info.document} ${key}`}
              name={key}
              style={input}
              type="text"
              value={data.info[key] || infoInitialState[key]}
              onChange={handleChange}
              onBlur={(e) => {
                const {name, value} = e.target
                data.info.onInfoChange(id, {[name]: value})
              }}
            />
          </label>
        ): data.info.document}
      </div>
      <Handle type="source" position="right" />
      <Handle type="target" position="left" />
    </div>
  );
};



export default ButtonNode