import React, { useState } from 'react';
import { useStoreContext } from '../state';


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const {setToken} = useStoreContext();

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await fetch('http://localhost:9005/auth/login/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    });
    const data = await response.json();
    setToken(data);  // Save JWT to localStorage
  };

  return (
    <>
        Please log in
        <form onSubmit={handleLogin}>
        <input type="username" value={username} onChange={e => setUsername(e.target.value)} />
        <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
        <button type="submit">Login</button>
        </form>
    </>
  );
}

export default Login;
