import { useEffect, useState, useCallback, useRef, createContext, useContext} from 'react';
import {
    useNodesState,
    useEdgesState,
    addEdge,
  } from 'reactflow';

const StoreContext = createContext();
export const useStoreContext = () => useContext(StoreContext);

const useLocalState = (key, initialValue) => {
    // Initialize state from localStorage or default to initialValue
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
        return initialValue;
        }
    });

    const storedValueRef = useRef(storedValue);

    // Update the ref and localStorage whenever the state changes
    useEffect(() => {
        storedValueRef.current = storedValue;
        try {
            window.localStorage.setItem(key, JSON.stringify(storedValue));
        } catch (error) {
            console.error(error);
        }
    }, [storedValue, key]);

    const setValue = useCallback((value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValueRef.current) : value;
            setStoredValue(valueToStore);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const removeValue = useCallback(() => {
        try {
            window.localStorage.removeItem(key);
            setStoredValue(initialValue);
        } catch (error) {
            console.error(error);
        }
    }, [key, initialValue]);


    return [storedValue, setValue, removeValue];
};
  

const StoreProvider = ({ children }) => {

    const initialNodes = [];
    const initialEdges = [];
    const initialGroups = [];

    const [storeNodes, setStoreNodes, removeStoreNodes] = useLocalState("nodes", initialNodes);
    const [storeEdges, setStoreEdges, removeStoreEdges] = useLocalState("edges", initialEdges);
    const [storeGroups, setStoreGroups, removeStoreGroups] = useLocalState("groups", initialGroups);

    const [token, setToken, removeToken] = useLocalState("token", "");

    const [counter, setCounter, resetCounter] = useLocalState("count", 1);
    
    const [nodes, setNodes, onNodesChange] = useNodesState(() => {
        return [
            ...storeNodes.map(obj => { return {
                ...obj,
                data: {
                    info:{
                        ...obj.data.info,
                        onDelete: () => deleteNode(obj.id),
                        onInfoChange: (id, info) => updateStoreNodes({id, info}),
                    },
                    controls: {
                        ...obj.data.controls,
                        onControlsChange:(id, controls) => updateStoreNodes({id, controls}),
                    }
                    
                }
            }}),
            ...storeGroups
        ]
    });

    const [edges, setEdges, onEdgesChange] = useEdgesState(() => {
        return storeEdges
    });


    const updateStoreNodes = ({id, info, controls}) => {
        const update = (prevState) => {
            const piece = {...prevState.filter(s => s.id == id)[0]}
            if (info) {
                piece.data.info = {...piece.data.info, ...info}
            }
            if (controls) {
                piece.data.controls = {...piece.data.controls, ...controls}
            }
            return [
                piece,
                ...prevState.filter(s => s.id !== id),
            ]
        }
        setStoreNodes(update)
        setNodes(update)
    }

    const addNode = () => {

        const node = {
            id: counter.toString(),
            position: { 
                x: 15*counter,
                y: 15*counter 
            },
            type: "buttonNode",
            data: {
                info: {
                    label: `Node ${counter.toString()}`,
                    onDelete: () => deleteNode(counter.toString()),
                    onDataChange: (id, info) => {updateStoreNodes({id, info})},
                },
                controls: {
                    collapse: false,
                    onControlChange: (id, controls) => updateStoreNodes({id, controls}),
                }
            },
        }

        setNodes((prevNodes)=>[...prevNodes, node])
        setStoreNodes((prevNodes)=>[...prevNodes, node])
        setCounter((counter)=>counter + 1)
    }


    const handleNodesChange = (changes) => {
        changes.forEach(change => {
            if (change["position"]) {
                
                setStoreNodes((storeNodes) => {
                    const n = storeNodes.filter((x)=>change.id === x.id)[0]
                    const {type, ...rest} = change
                    return [
                        ...storeNodes.filter((x)=>change.id !== x.id),
                        {...n, ...rest}
                    ]
                })
            }
        });

        onNodesChange(changes);
    };

    const deleteNode = (id) => {
        const update = (prevNodes) => [...prevNodes.filter((n)=> id !== n.id)]
        setNodes(update)
        setStoreNodes(update)

    }

    const resetNodes = () => {
        setNodes([])
        removeStoreNodes()
        setEdges([])
        removeStoreEdges()
        resetCounter()
    };

    const seeEdges = () => {
        console.log(edges, storeEdges)
    }


    const handleEdgeChange = (changes) => {
        
        // console.log(changes);
        onEdgesChange(changes);
    }

    const onConnect = useCallback(
        (params) => {
            return setEdges((eds) => {
                const typeParams = {...params}
                typeParams.type = "buttonedge";
                const tempEdge = addEdge(typeParams, eds)
                setStoreEdges(()=>[...tempEdge]);
                return tempEdge
            })
        },
        [],
    );

    const deleteEdge = (id) => {
        setStoreEdges((storeEdges)=> storeEdges.filter((e)=> id !== e.id))
    }

    const closeNodes = () => {
        setNodes(prevState => {
            return prevState.forEach(x => {return x.controls.collapse = true})
        })
    }

    const getNode = (id) => {
        return nodes.filter(x=>x.id===id)[0]
    }

    const getPrevious = (id) => {
        const prev = storeEdges.filter(x => id === x.target).map(x => getNode(x.source))
        console.log(prev)
    }

    const getNext = (id) => {
        const next = storeEdges.filter(x => id === x.source).map(x => getNode(x.target))
        console.log(next)
    }

    return (
        <StoreContext.Provider value={{
            nodes: nodes, 
            edges: edges,
            getNode: getNode,
            setNodes: setNodes,
            onNodesChange: handleNodesChange,
            onEdgesChange: handleEdgeChange,
            addNode: addNode,
            deleteNode: deleteNode,
            onConnect: onConnect,
            resetNodes: resetNodes,
            seeEdges: seeEdges,
            deleteEdge: deleteEdge,
            setStoreNodes: setStoreNodes,
            closeNodes,
            getPrevious,
            getNext,
            token,
            setToken,
        }}>
          {children}
        </StoreContext.Provider>
      );
}

export default StoreProvider