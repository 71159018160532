import React, { useCallback, useState } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
} from 'reactflow';
// import useCustomNodeState from './state';
import { useStoreContext } from './state';
import { useJwt } from "react-jwt";
import ButtonEdge from './edges/deleteableEdge';
import ButtonNode from './nodes/deleteableNode';
import Login from './login/form';
import 'reactflow/dist/style.css';

// import './nodes/index.css';

const edgeTypes = {
  buttonedge: ButtonEdge,
};

const nodeTypes = {
  buttonNode: ButtonNode
}

export default function App() {

  const { 
    seeEdges, 
    addNode, 
    resetNodes, 
    onNodesChange, 
    onEdgesChange, 
    onConnect, 
    nodes, 
    edges, 
    closeNodes,
    token, 
    setToken,} = useStoreContext();
  

  const { decodedToken, isExpired } = useJwt(token.access);
  console.log(decodedToken, isExpired)

  const logOut = () => {
    setToken("")
  }
  
  return (
    decodedToken ?
    <div style={{ width: '100vw', height: '100vh', overflow: "hidden" }}>
      <button type="button" onClick={addNode}>Add Node</button>
      <button type="button" onClick={resetNodes}>Reset</button>
      <button type="button" onClick={seeEdges}>Edge</button>
      <button type="button" onClick={closeNodes}>Collapse</button>
      <button type="button" style={{"float": "right"}} onClick={logOut}>Logout</button>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        fitView
      >
        <Controls />
        <MiniMap />
        <Background variant="dots" gap={12} size={1} />
      </ReactFlow>
    </div>
    :
    <Login/> 
  );
}